var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.returnPrevious()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"form":"vehicleForm","color":"primary","type":"submit","loading":_vm.isVehicleSubmitted}},[_vm._v(" "+_vm._s(_vm.$t('btn.save'))+" ")])],1)],2),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary","inline":"","dark":_vm.$vuetify.theme.dark,"icon":"mdi-wallet-travel"}},[_c('template',{slot:"bottom"},[_c('span',{staticClass:" text-subtitle-1 pl-2"},[_vm._v(_vm._s(_vm.$t('form.required')))])]),_c('template',{slot:"title"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('vehicle.form.title'))+" ")])],1),_c('validation-observer',{ref:"validationObserver"},[_c('v-form',{staticClass:"pt-8",attrs:{"id":"vehicleForm"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.reference'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-2",attrs:{"error-messages":errors,"label":_vm.$t('vehicle.form.reference') + '*',"outlined":""},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('SelectArea',{attrs:{"items":_vm.listAreas,"label":_vm.$t('vehicle.form.area'),"outlined":"","classes":"ml-3 mb-4"},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.matriculation'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-2",attrs:{"error-messages":errors,"label":_vm.$t('vehicle.form.matriculation') + '*',"outlined":""},model:{value:(_vm.matriculation),callback:function ($$v) {_vm.matriculation=$$v},expression:"matriculation"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('DateField',{attrs:{"label":_vm.$t('vehicle.form.date_first_matriculation'),"text-field-class":"ml-3 mb-2"},model:{value:(_vm.firstDateMatriculation),callback:function ($$v) {_vm.firstDateMatriculation=$$v},expression:"firstDateMatriculation"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('DateField',{attrs:{"label":_vm.$t('vehicle.form.inactive_until_date'),"text-field-class":"ml-3 mb-2","is-clearable":true,"rules":""},model:{value:(_vm.inactiveUntilDate),callback:function ($$v) {_vm.inactiveUntilDate=$$v},expression:"inactiveUntilDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('SelectCarBrand',{staticClass:"ml-3 mb-6",model:{value:(_vm.carBrand),callback:function ($$v) {_vm.carBrand=$$v},expression:"carBrand"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectCarModel',{staticClass:"ml-3 mb-6",attrs:{"car-brand":_vm.carBrand,"error-messages":errors},model:{value:(_vm.carModel),callback:function ($$v) {_vm.carModel=$$v},expression:"carModel"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('SelectCarEngine',{staticClass:"ml-3 mb-6",attrs:{"car-model":_vm.carModel},model:{value:(_vm.carEngine),callback:function ($$v) {_vm.carEngine=$$v},expression:"carEngine"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('SelectCarFinish',{staticClass:"ml-3 mb-6",attrs:{"car-model":_vm.carModel},model:{value:(_vm.carFinish),callback:function ($$v) {_vm.carFinish=$$v},expression:"carFinish"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.category')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"items":['VL', 'VU', 'VUL', 'VAE'],"label":_vm.$t('vehicle.form.category'),"outlined":""},nativeOn:{"input":function($event){return (function (e) { return (_vm.category = e.target.value); })($event)}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}])})],1),_c('v-col',{attrs:{"md":"6"}},[_c('SelectCarColor',{staticClass:"ml-3",model:{value:(_vm.carColor),callback:function ($$v) {_vm.carColor=$$v},expression:"carColor"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"md-2 ml-3"},[_c('DateField',{attrs:{"rules":"","is-clearable":true,"label":_vm.$t('vehicle.form.date_last_mileage_statement')},model:{value:(_vm.mileageStatementDate),callback:function ($$v) {_vm.mileageStatementDate=$$v},expression:"mileageStatementDate"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.car_mileage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ml-3 mb-4",attrs:{"type":"number","error-messages":errors,"label":_vm.$t('vehicle.form.car_mileage') + '*',"outlined":""},model:{value:(_vm.carMileage),callback:function ($$v) {_vm.carMileage=_vm._n($$v)},expression:"carMileage"}})]}}])})],1),_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.fuel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"items":[
                  'Diesel',
                  'Sans plomb 95',
                  'Sans plomb 98',
                  'Électrique',
                  'GVN',
                  'Hydrogène' ],"item-value":"@id","item-text":"name","label":_vm.$t('vehicle.form.fuel'),"outlined":""},model:{value:(_vm.fuel),callback:function ($$v) {_vm.fuel=$$v},expression:"fuel"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"md-2 ml-3"},[_c('DateField',{attrs:{"label":_vm.$t('vehicle.form.date_renew_at')},model:{value:(_vm.renewAt),callback:function ($$v) {_vm.renewAt=$$v},expression:"renewAt"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.gearbox')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"ml-3 mb-4",attrs:{"error-messages":errors,"items":['Manuel', 'Automatique'],"item-value":"@id","item-text":"name","label":_vm.$t('vehicle.form.gearbox'),"outlined":""},model:{value:(_vm.gearbox),callback:function ($$v) {_vm.gearbox=$$v},expression:"gearbox"}})]}}])})],1),_c('v-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('vehicle.form.renter')}},[_c('SelectRenter',{staticClass:"ml-3 mb-4",attrs:{"renter":_vm.renter},model:{value:(_vm.renter),callback:function ($$v) {_vm.renter=$$v},expression:"renter"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-textarea',{staticClass:"ml-3 mb-4",attrs:{"label":_vm.$t('vehicle.form.commentary'),"outlined":""},model:{value:(_vm.commentary),callback:function ($$v) {_vm.commentary=$$v},expression:"commentary"}})],1)],1)],1)],1)],2),_c('ScrollTop'),_c('cancel-update-dialog',{attrs:{"saving":_vm.isVehicleSubmitted},on:{"cancel":function($event){return _vm.onCancel()},"save":function($event){return _vm.onSave()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }