




















































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import TopBar from '@/components/organisms/o-top-bar.vue'
import store from '@/store'
import { Vehicle } from '@/api/interfaces/vehicle'
import { RawLocation } from 'vue-router'

export default defineComponent({
  name: 'Vehicle',
  components: {
    CancelUpdateDialog: () =>
    import('../../../components/cancel-update-dialog.vue'),
    DateField: () => import('@/components/base/DateField.vue'),
    SelectArea: () =>
    import('@/components/molecules/select/area.vue'),
    SelectCarBrand: () =>
    import('@/components/molecules/select-search/carBrand.vue'),
    SelectCarModel: () =>
    import('@/components/molecules/select-search/carModel.vue'),
    SelectCarEngine: () =>
    import('@/components/molecules/select-search/carEngine.vue'),
    SelectCarFinish: () =>
    import('@/components/molecules/select-search/carFinish.vue'),
    SelectCarColor: () =>
    import('@/components/molecules/select-search/carColor.vue'),
    SelectRenter: () =>
    import('@/components/molecules/select-search/renter.vue'),
    TopBar,
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
  },
  data () {
    return {
      title: this.$t('vehicle.title'),
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    // The `mapFields` function takes an array of
    // field names and generates corresponding
    // computed properties with getter and setter
    // functions for accessing the Vuex store.
    ...mapFields('vehicleForm', [
      'vehicle.carModel',
      'vehicle.carBrand',
      'vehicle.carEngine',
      'vehicle.carFinish',
      'vehicle.reference',
      'vehicle.matriculation',
      'vehicle.firstDateMatriculation',
      'vehicle.area',
      'vehicle.carColor',
      'vehicle.carMileage',
      'vehicle.mileageStatementDate',
      'vehicle.fuel',
      'vehicle.renewAt',
      'vehicle.commentary',
      'vehicle.gearbox',
      'vehicle.inactiveUntilDate',
      'vehicle.renter',
      'vehicle.category',
    ]),
    ...mapState('vehicleForm', {
      vehicle: 'vehicle',
      isVehicleSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapState('carColor', {
      listCarColors: 'list',
    }),
    ...mapGetters('navigation', {
      returnPreviousRoute: 'getReturnPreviousRoute',
      getLastRouteLogisticSession: 'getLastRouteLogisticSession',
    }),
    idVehicle () {
      return this.$route.params.idVehicle || null
    },
  },
  async created () {
    this.loadCarColors()
    await this.init()
  },
  watch: {
    idVehicle () {
      this.init()
    },
  },
  methods: {
    ...mapActions('vehicleForm', {
      save: 'save',
      reset: 'reset',
    }),
    ...mapActions('carColor', {
      loadCarColors: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      addVehicleToLogisticSession: 'addVehicle',
    }),
    async init () {
      if (this.idVehicle) {
        if (
          !this.vehicle ||
          (this.vehicle as Vehicle).id !== parseInt(this.idVehicle as string)
        ) {
          await this.loadVehicleById(this.idVehicle)
        }
      } else {
        this.resetVehicle()
      }
    },
    ...mapActions('vehicleForm', {
      loadVehicleById: 'loadById',
      resetVehicle: 'reset',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(this.$refs.validationObserver)
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('vehicle.form.saved')
        if (this.getLastRouteLogisticSession) {
          this.addVehicleToLogisticSession(this.vehicle)
        }
        this.returnPrevious()
      }
    },
    returnPrevious (): void {
      if (this.returnPreviousRoute) {
        this.$router.push(this.returnPreviousRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Vehicle List' })
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    store.commit('navigation/removeRoute')
    next()
  },
})
